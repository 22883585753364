import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import Config from "../../config.json";
import { useAuth } from "../../AuthContext";

function Table({ assistantId }) {
  const [files, setFiles] = useState([]);
  const { user, jwtToken } = useAuth();

  // console.log("assistant ID in Table", assistantId);

  useEffect(() => {
    if (!assistantId) return;

    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `${Config["API_URL"]}/assistants/${assistantId}/files/`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setFiles(response.data);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };
    // asst_eSLmjv9v5oIwrAOH8pzxibzC
    fetchFiles();
  }, [assistantId]);

  // console.log(files);

  const handleDelete = async (fileId) => {
    try {
      await axios.delete(`${Config["API_URL"]}/files/${fileId}/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setFiles(files.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleDownload = async (fileId, fileName) => {
    try {
      const response = await axios.get(
        `${Config["API_URL"]}/files/download/${fileId}/`,
        {
          responseType: "blob",
        }
      );

      const contentDisposition = response.headers["content-disposition"];
      let filename = fileName;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]*)"?/);
        if (filenameMatch && filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div className="relative overflow-x-auto mt-10">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded">
          <thead className="text-xs text-gray-700 uppercase bg-vesta-green rounded dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="md:px-6 md:py-3 px-2 py-2">
                Serial No.
              </th>
              <th scope="col" className="md:px-6 md:py-3 px-2 py-2">
                Name
              </th>
              <th scope="col" className="md:px-6 md:py-3 px-2 py-2">
                Download
              </th>
              <th scope="col" className="md:px-6 md:py-3 px-2 py-2">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {files.map((file, index) => (
              <tr key={file.id} className="bg-white border">
                <th
                  scope="row"
                  className="md:px-6 md:py-4 px-1 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <td className="md:px-6 md:py-4 px-2 py-2">{file.filename}</td>
                <td className="md:px-6 md:py-4 px-2 py-2">
                  <button
                    className="text-blue-600 hover:underline"
                    onClick={() => handleDownload(file.id, file.filename)}
                  >
                    Dowload
                  </button>
                </td>
                <td className="md:px-6 md:py-4 px-1 py-2">
                  <button
                    className="text-red-600 hover:underline flex justify-center items-center pl-4"
                    onClick={() => handleDelete(file.id)}
                  >
                    <MdDelete size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;
