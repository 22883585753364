import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { TbSend } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { BsRobot } from "react-icons/bs";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import Config from "../config.json";
import { FaLanguage } from "react-icons/fa6";
import { RiFeedbackFill } from "react-icons/ri";

const Chatbot = ({ streamId }) => {
  const { user, jwtToken } = useAuth();
  const [messages, setMessages] = useState({});
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [language, setLanguage] = useState(user.language);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [isNegative, setIsNegative] = useState(false);
  const [isThumbsUpActive, setIsThumbsUpActive] = useState(false);
  const [isThumbsDownActive, setIsThumbsDownActive] = useState(false);

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsThumbsDownActive(false);
        setIsThumbsUpActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const handleThumbsUpClick = () => {
    setIsThumbsUpActive(true);
    setIsThumbsDownActive(false);
    setIsNegative(false);
  };

  const handleThumbsDownClick = () => {
    setIsThumbsDownActive(true);
    setIsThumbsUpActive(false);
    setIsNegative(true);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsThumbsDownActive(false);
    setIsThumbsUpActive(false);
  };

  const handleClick = () => {
    setIsActive(!isActive); // Toggle active state on click
  };
  const handleClick1 = () => {
    setIsActive(!isActive); // Toggle active state on click
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleUpdate = async (newLanguage) => {
    try {
      const response = await axios.patch(
        `${Config["API_URL"]}/user/update/${user.id}/`,
        {
          language: newLanguage,
          role: user.role,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      console.log("User updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${Config["API_URL"]}/user/${user.id}/stream/${streamId}/all-messages/`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      if (response.status === 200) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          [streamId]: response.data.messages,
        }));
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleCreate = async (messageId, is_neg, feedback) => {
    try {
      const response = await axios.post(
        `${Config["API_URL"]}/feedback/`,
        {
          user_id: user.id,
          message_id: messageId,
          is_negative: is_neg,
          feedback_msg: feedbackMsg,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.status === 200) {
        setFeedbackMsg("");
        setIsNegative(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMessages(); // Fetch messages when streamId or user changes

    // Scroll to the bottom of the messages container
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [user.id, streamId, jwtToken]);

  // Handle enter key press
  const handleKeyDown = async (e) => {
    if(e.code == "Enter"){
      submitMessageCommon();
    } else {
      // console.log("Key pressed: ", e.code);
    }
  }
  const submitMessageCommon = async ()=>{
    if (!prompt.trim()) return;

    const newMessage = {
      author: "user",
      message_value: prompt,
    };

    const updatedCurrentMessages = [...(messages[streamId] || []), newMessage];
    setMessages((prevMessages) => ({
      ...prevMessages,
      [streamId]: updatedCurrentMessages,
    }));
    setPrompt(""); // Clear the input field immediately
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${Config["API_URL"]}/user/${user.id}/stream/${streamId}/messages/`,
        { prompt: prompt },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.status === 200) {
        const assistantMessage = {
          author: "assistant",
          message_value: response.data.value,
        };
        setMessages((prevMessages) => ({
          ...prevMessages,
          [streamId]: [...prevMessages[streamId], assistantMessage],
        }));
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    submitMessageCommon();
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="flex flex-col items-center justify-center md:px-6 md:py-8 pt-8 pb-2 mx-auto h-full lg:py-0 overscroll-none">
      <div className="flex flex-col h-full w-full rounded-lg border border-gray-300 w-1/2">
        <div ref={chatContainerRef} className="flex-1 p-4 overflow-y-auto">
          {messages[streamId]?.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.author === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`mb-4 p-4 rounded-lg ${
                  message.author === "user" ? "bg-vesta-light" : "bg-blue-100"
                }`}
              >
                <div className="flex">
                  {message.author === "assistant" && (
                    <div className="mr-2 flex-shrink-0">
                      <BsRobot size={20} />
                    </div>
                  )}
                  <pre className="flex whitespace-pre-wrap">
                    {message.message_value}
                  </pre>
                  {message.author === "assistant" && (
                    <div className="ml-2 flex flex-shrink-0 pt-1">
                      <button
                        className="bg-blue-500 text-white  rounded"
                        onClick={openModal}
                      >
                        <RiFeedbackFill />
                      </button>
                    </div>
                  )}
                  {message.author === "user" && (
                    <div className="ml-2 flex-shrink-0">
                      <FaUser />
                    </div>
                  )}
                  {isOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50">
                      <div
                        ref={modalRef}
                        className="bg-[#FFFFFF] p-6 rounded shadow-lg md:w-1/4 w-3/4 flex flex-col justify-center bg-opacity-50"
                      >
                        <h2 className="text-xl font-semibold mb-4">Feedback</h2>
                        <div className="flex justify-center">
                          <button
                            className={`border border-1 rounded px-8 py-2 m-4 ${
                              isThumbsUpActive
                                ? "bg-vesta-light text-white"
                                : "bg-white"
                            }`}
                            onClick={() => {
                              handleThumbsUpClick();
                              // setIsNegative(false);
                            }}
                          >
                            <FaRegThumbsUp size={30} />
                          </button>
                          <button
                            className={`border border-1 rounded px-8 py-2 m-4 ${
                              isThumbsDownActive
                                ? "bg-vesta-light text-white"
                                : "bg-white"
                            }`}
                            onClick={() => {
                              handleThumbsDownClick();
                              // setIsNegative(true);
                            }}
                          >
                            <FaThumbsDown size={30} />
                          </button>
                        </div>

                        <label
                          for="message"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Your Feedback
                        </label>
                        <textarea
                          id="message"
                          rows="4"
                          className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Write your feedback here..."
                          onChange={(e) => {
                            setFeedbackMsg(e.target.value);
                          }}
                        ></textarea>

                        {/* Send button to close the modal */}
                        <button
                          className="bg-vesta-green text-white px-4 py-2 rounded mt-4"
                          onClick={() => {
                            closeModal();
                            handleCreate(
                              message.message_id,
                              isNegative,
                              feedbackMsg
                            );
                          }}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          {isLoading && (
            <div
              className="flex justify-center items-center mt-4"
              role="status"
            >
              <svg
                aria-hidden="true"
                className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-vesta-green"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="max-w-full md:w-full mx-auto">
          {showDropdown && (
            <select
              id="countries"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={language}
              onChange={(e) => {
                const newLanguage = e.target.value;
                setLanguage(e.target.value);
                toggleDropdown();
                handleUpdate(newLanguage); // Call handleUpdate whenever the language changes
              }}
            >
              {" "}
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Punjabi">Punjabi</option>
            </select>
          )}
        </div>
        <div
          // onSubmit={handleSubmit}
          className="flex p-4 border-t border-gray-300"
        >
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type your message..."
            className="flex-1 p-2 rounded-lg"
          />
          <button onClick={toggleDropdown} className="flex items-center ml-4">
            <FaLanguage size={25} />
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="ml-2 p-2 bg-white text-white rounded-lg px-4"
            disabled={isLoading}
          >
            <TbSend size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
