import React, { useState, useEffect } from "react";
import axios from "axios";
import Nav from "./Widgets/Nav";
import Table from "./Widgets/Table";
import Navbar from "./Widgets/Navbar";
import Upload from "./Widgets/Upload";
import { PiBellRingingBold } from "react-icons/pi";

function Update({ assistantId }) {
  return (
    <>
      <Navbar />
      <div className="p-4  sm:ml-64 h-screen">
        <div className="md:h-1/4 relative"></div>
        <div className="w-full border rounded-md pb-10 px-10">
          <div className="flex flex-col items-center justify-center content-center">
            <Upload assistantId={assistantId} />
          </div>
          <Table assistantId={assistantId} />
        </div>
      </div>
    </>
  );
}

export default Update;
