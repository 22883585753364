import React, { useState, useEffect } from "react";
import Navbar from "./Widgets/Navbar";
import { SlUser } from "react-icons/sl";
import { PiBellRingingBold } from "react-icons/pi";
import { PiHandWavingBold } from "react-icons/pi";
import { useAuth } from "../AuthContext";
import axios from "axios";
import Config from "../config.json";

function Profile1() {
  const { user, logout } = useAuth();
  const { jwtToken } = useAuth();
  const [language, setLanguage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // setLanguage(user.language);
  // console.log(user.language);

  const handleUpdate = async () => {
    try {
      const response = await axios.patch(
        `${Config["API_URL"]}/user/update/${user.id}/`,
        {
          language: language,
          role: user.role,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      console.log("User updated successfully:", response.data);
      setSuccessMessage("User updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
      setSuccessMessage("Error updating user");
    }
  };

  return (
    <>
      <Navbar />

      <div className="p-4  sm:ml-64 h-screen flex flex-col items-center justify-center content-center">
        <div className="md:h-1/4 relative"></div>
        <div className="w-full md:h-3/4 border rounded-md flex flex-col items-center justify-center content-center p-4">
          <SlUser size={75} />

          <div className="flex items-center justify-center content-center mt-8">
            <p className="text-xl flex">Mobile Number : </p>
            <p className="md:text-3xl text-xl font-bold flex text-vesta-green ml-4">
              {user.mobile_num}{" "}
            </p>
          </div>
          <div className="flex items-center justify-center content-center mt-2">
            <p className="text-xl flex">Email : </p>
            <p className="md:text-3xl text-xl font-bold flex text-vesta-green ml-4">
              {user.email}{" "}
            </p>
          </div>
          {/* <div className="flex w-96 justify-between mt-4">
            <p className="text-xl flex">Language : </p>
            <select
              id="countries"
              className=" text-lg rounded-md border-1 border border-gray px-8 py-1 flex ml-4"
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option selected>{user.language}</option>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Punjabi">Punjabi</option>
            </select>
          </div>
          <p>{successMessage}</p>
          <button
            type="button"
            className="w-1/4 mt-12 border-2 py-2 px-6 rounded text-vesta-green"
            onClick={handleUpdate}
          >
            Save
          </button> */}
          <button
            type="button"
            className="w-1/4 md:mt-36 mt-16 border-2 py-2 px-6 rounded text-red flex flex-col items-center justify-center"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
}

export default Profile1;
