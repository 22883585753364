import React, { createContext, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Config from "./config.json";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [jwtToken, setJwtToken] = useState(
    localStorage.getItem("jwtToken") || ""
  );
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const login = async (mobileNumber, password) => {
    setMessage("");
    // console.log(mobileNumber);
    // console.log(password);
    try {
      const response = await axios.post(
        `${Config["API_URL"]}/login/`,
        new URLSearchParams({
          username: mobileNumber,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        const userData = response.data;
        const token = response.data.access_token; // Assuming the token is in the response
        // console.log(response.status);

        localStorage.setItem("user", JSON.stringify(userData)); // Save user data in local storage
        localStorage.setItem("jwtToken", token);

        setJwtToken(token);
        setMessage("Login successful!");
        navigate("/");
      } else {
        setMessage("Login failed.");
      }
    } catch (error) {
      setMessage(
        "Error logging in. Please check your credentials or try again later."
      );
      console.error("Error logging in:", error);
    }
  };

  const logout = () => {
    localStorage.removeItem("user"); // Remove user data from local storage
    localStorage.removeItem("jwtToken");

    setJwtToken("");
    setMessage("");
    navigate("/login");
  };

  const getUserFromLocalStorage = () => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  };

  const value = {
    user: getUserFromLocalStorage(), // Retrieve user data from local storage
    jwtToken,
    message,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
